<template>
  <md-toolbar md-elevation="0" class="md-danger">
    <div class="md-toolbar-row">
      <div class="md-toolbar-section-start">
        <h3 class="md-title">{{ $route.name }}</h3>
      </div>
      <div class="md-toolbar-section-end">
        <md-button
          class="md-just-icon md-simple md-toolbar-toggle"
          :class="{ toggled: $sidebar.showSidebar }"
          @click="toggleSidebar"
        >
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
        </md-button>
        <div style="display: flex; align-items: center">
          <router-link to="/notifications" class="notify-top">
            <notify-icon />
            <span v-if="count > 0">{{ count }}</span>
          </router-link>

          <div class="md-collapse">
            <md-list>
              <li class="md-list-item">
                <a
                  href="#"
                  class="md-list-item-router md-list-item-container md-button-clean dropdown"
                >
                  <div class="md-list-item-content">
                    <drop-down>
                      <md-button
                        slot="title"
                        class="md-button md-just-icon md-simple"
                        data-toggle="dropdown"
                      >
                        <i
                          class="material-icons"
                          style="color: white !important"
                          >person</i
                        >

                        <p class="hidden-lg hidden-md">Notifications</p>
                      </md-button>
                      <ul class="dropdown-menu dropdown-menu-right">
                        <li><a href="#">Profile</a></li>
                        <li><a href="#">Visit Site</a></li>
                        <li><a href="#">Settings</a></li>
                        <md-divider></md-divider>
                        <li><a href="#" @click="handleLogout">Log out</a></li>
                      </ul>
                    </drop-down>
                  </div>
                </a>
              </li>
            </md-list>
          </div>
        </div>
      </div>
    </div>
  </md-toolbar>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import { NotifyIcon } from "@/components";

const { mapActions } = createNamespacedHelpers("auth");
const { mapGetters } = createNamespacedHelpers("dataStore");

export default {
  components: {
    NotifyIcon,
  },
  data() {
    return {};
  },
  methods: {
    ...mapActions(["logout"]),
    handleLogout() {
      this.logout().then(() => {
        this.$router.push("/login");
      });
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
  },
  computed: {
    ...mapGetters(["count"]),
  },
};
</script>

<style lang="scss">
.notify-top {
  color: #fff !important;
  position: relative;
  span {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20px;
    width: 20px;
    border-radius: 500px;
    background: #04a431;
    font-size: 10px;
    top: -8px;
    right: -8px;
  }
}
</style>
