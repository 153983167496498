<template>
  <footer class="footer">
    <div class="container">
      <nav>
        <ul></ul>
      </nav>
      <div class="copyright text-center">
        &copy; {{ new Date().getFullYear() }}
        <a href="https://buildingsandmore.ng" target="_blank">BAML</a>, made
        with <i class="fa fa-heart heart"></i> for a better web
      </div>
    </div>
  </footer>
</template>
<script>
export default {};
</script>
<style></style>
