<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <notifications></notifications>

    <SideNav />

    <div class="main-panel">
      <top-navbar></top-navbar>

      <!-- <fixed-plugin
        :color.sync="sidebarBackground"
        :image.sync="sidebarBackgroundImage"
      >
      </fixed-plugin> -->

      <dashboard-content> </dashboard-content>
      <Promotion
        v-if="promotion"
        @close="show = false"
        :show="show"
        :promotion="promotion"
      />

      <content-footer v-if="!$route.meta.hideFooter"></content-footer>
    </div>
  </div>
</template>

<script>
import TopNavbar from "../components/TopNavbar.vue";
import SideNav from "../components/Aside.vue";
import ContentFooter from "../components/ContentFooter.vue";
import DashboardContent from "../components/Content.vue";
import Promotion from "../components/Promotion.vue";
// import FixedPlugin from "./Extra/FixedPlugin.vue";
import { createNamespacedHelpers } from "vuex";
const { mapActions } = createNamespacedHelpers("auth");

export default {
  components: {
    TopNavbar,
    DashboardContent,
    ContentFooter,
    SideNav,
    Promotion,
    // FixedPlugin,
  },
  data() {
    return {
      show: false,
      promotion: null,
    };
  },
  created() {
    this.getUser();
    this.$eventBus.$on("promote", (data) => {
      this.promotion = data;
      this.show = true;
    });
  },
  watch: {
    $route() {
      this.show = false;
    },
  },
  methods: {
    ...mapActions(["user"]),
    async getUser() {
      try {
        let res = await this.user();
        // this.$router.push('/dashboard')
        //this.$router.go(-1)
        console.log(res);
      } catch (error) {
        if (!error.response) {
          // network error
          this.errorStatus = "Error: Network Error";
        } else {
          this.errorStatus = error.response.data.message;
        }
      }
    },
  },
};
</script>
