<template>
  <ul class="nav nav-mobile-menu">
    <li class="md-list-item">
      <a
        href="#"
        class="md-list-item-router md-list-item-container md-button-clean dropdown"
      >
        <div class="md-list-item-content">
          <drop-down>
            <md-button
              slot="title"
              class="md-button md-just-icon md-simple"
              data-toggle="dropdown"
            >
              <i class="material-icons" style="color: white !important"
                >person</i
              >

              <p class="hidden-lg hidden-md">Notifications</p>
            </md-button>
            <ul class="dropdown-menu dropdown-menu-right">
              <li><a href="#">Profile</a></li>
              <li><a href="#">Visit Site</a></li>
              <li><a href="#">Settings</a></li>
              <md-divider></md-divider>
              <li><a href="#" @click="handleLogout">Log out</a></li>
            </ul>
          </drop-down>
        </div>
      </a>
    </li>
  </ul>
</template>
<script>
import { createNamespacedHelpers } from "vuex";
const { mapActions } = createNamespacedHelpers("auth");
export default {
  data() {
    return {};
  },
  methods: {
    ...mapActions(["logout"]),
    handleLogout() {
      this.logout().then(() => {
        this.$router.push("/login");
      });
    },
  },
};
</script>
