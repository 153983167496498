<template>
  <div>
    <md-dialog :md-active.sync="show">
      <div class="promotion-container">
        <div class="cancel">
          <i class="material-icons" @click="$emit('close')">close</i>
        </div>
        <form novalidate @submit.prevent="validateUser">
          <div class="header">
            <h2>{{ getPromotions(promotion.item_type) }} Promotion</h2>
          </div>
          <h4>
            {{ promotion.title }}
          </h4>

          <md-field :class="getValidationClass('start_date')">
            <label>Start Date</label>
            <md-input v-model="form.start_date" required type="date"></md-input>
            <span class="md-error" v-if="!$v.form.start_date.required"
              >Start date is required</span
            >
          </md-field>

          <md-field :class="getValidationClass('end_date')">
            <label>End Date</label>
            <md-input v-model="form.end_date" required type="date"></md-input>
            <span class="md-error" v-if="!$v.form.end_date.required"
              >End date required</span
            >
          </md-field>

          <div class="text-center">
            <mdc-button :loading="loading">{{
              promotion.mode == "create" ? "Promote" : "Update"
            }}</mdc-button>
          </div>

          <div v-if="promotion.mode !== 'create'">
            <md-button
              type="button"
              class="md-primary md-icon-button md-raised btn-icon"
              @click="remove(promotion.promote.id)"
              :loading="loading"
              :disabled="loading"
            >
              <i class="material-icons">delete</i>
            </md-button>
          </div>
        </form>
      </div>
      <!-- <div>{{ promotion.item_id }}</div>
      <div>{{ promotion.item_type }}</div> -->
    </md-dialog>
  </div>
</template>
<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin],

  props: ["show", "promotion"],
  data: () => ({
    loading: false,
    form: {
      start_date: "",
      end_date: "",
    },
  }),
  validations: {
    form: {
      start_date: {
        required,
      },
      end_date: {
        required,
      },
    },
  },
  methods: {
    async validateUser() {
      this.$v.$touch();
      console.log(this.form);
      if (!this.$v.$invalid) {
        try {
          this.loading = true;
          this.form.category_id = this.promotion.item_type;
          this.form.item_id = this.promotion.item_id;
          let action =
            this.promotion.mode == "create"
              ? "promotion/createPromotion"
              : "promotion/updatePromotion";
          let msg =
            this.promotion.mode == "create"
              ? "Promotion Created"
              : "Promotion Updated";
          await this.$store.dispatch(action, this.form);
          this.toast("success", msg);
          this.loading = false;
          this.$eventBus.$emit("promoted", this.promotion.item_id);
          this.$emit("close");
        } catch (err) {
          this.loading = false;
          //   this.parseError(err);
          this.toast("error", "Opps, Something went wrong ");
        }
      }
    },
    async remove(id) {
      try {
        const conf = confirm("Are you sure ?");
        if (conf) {
          this.loading = true;
          await this.$store.dispatch("promotion/removePromotion", id);
          this.toast("success", "Promotion Removed");
          this.$eventBus.$emit("promoted", id);
          this.loading = false;
          this.$emit("close");
        }
      } catch (err) {
        this.loading = false;
        this.toast("error", "Opps, Something went wrong ");
      }
    },
    getPromotions(id) {
      switch (id) {
        case 1:
          return "Handyman";
          break;
        case 2:
          return "Professional";
          break;
        case 3:
          return "Reale state";
          break;
        case 4:
          return "Product";
          break;
        case 5:
          return "Account";
          break;
        case 6:
          return "Portfolio";
          break;
        default:
          break;
      }
    },
  },
  watch: {
    show(val) {
      if (this.promotion.mode == "update") {
        this.form.start_date = this.promotion.promote.start_date;
        this.form.end_date = this.promotion.promote.end_date;
        this.form.id = this.promotion.promote.id;
      } else {
        this.form.start_date = "";
        this.form.end_date = "";
      }
    },
  },
  mounted() {
    if (this.promotion.mode == "update") {
      this.form.start_date = this.promotion.promote.start_date;
      this.form.end_date = this.promotion.promote.end_date;
      this.form.id = this.promotion.promote.id;
    }
  },
  computed: {},
};
</script>
<style scoped lang="scss">
.promotion-container {
  padding: 20px;
  position: relative;

  width: 500px;
  max-width: 100%;

  .cancel {
    position: absolute;
    top: 20px;
    cursor: pointer;
    right: 20px;
  }

  .header {
    padding: 0px 20px;
    text-align: center;

    h2 {
      font-size: 24px;
      font-weight: 700;
    }
  }

  h4 {
    font-size: 20px;
    font-weight: 500;
  }
}
</style>
