<template>
  <li class="nav-item-c">
    <router-link
      v-if="is_visible"
      :to="route_name"
      :class="{ active: route_name == $route.name }"
      tag="a"
      class="nav-item nav-link c-nav collapsed"
      :data-bs-toggle="has_children ? 'collapse' : ''"
      :aria-expanded="has_children ? true : false"
    >
      <i class="material-icons">{{ icon }}</i>
      <p>{{ title }}</p>
      <svg
        v-if="has_children"
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        class="bi bi-caret-down-fill"
        viewBox="0 0 16 16"
      >
        <path
          d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"
        />
      </svg>
    </router-link>
    <div v-if="has_children" class="collapse" :id="getId(route_name)">
      <ul class="nav">
        <li v-for="(child_item, index) in child" class="nav-item" :key="index">
          <router-link
            v-if="
              child_item.is_visible == undefined ? true : child_item.is_visible
            "
            tag="a"
            :to="{ name: child_item.route_name }"
            :class="{ active: child_item.route_name == $route.name }"
            style="color: inherit"
            class="nav-link nav-item"
          >
            <span class="sidebar-mini px-2">
              {{ child_item.alias }}
            </span>
            <span class="sidebar-normal">{{ child_item.title }}</span>
          </router-link>
        </li>
      </ul>
    </div>
  </li>

  <!-- <router-link
    v-if="is_visible"
    :to="route_link"
    v-slot="{ href, navigate }"
    :exact="is_exact"
  >
    <li
      :class="[
        has_children && 'treeview',
        isLinkActive && 'active',
        isLinkActive && has_children && 'menu-open',
      ]"
    >
      <a :href="href" @click="navigate">
        <i :class="icon"></i> <span>{{ title }}</span>

        <span v-if="has_children" class="pull-right-container">
          <i class="fa fa-angle-left pull-right"></i>
        </span>
      </a>
      <ul v-if="has_children" class="treeview-menu">
        <div v-for="(child_item, index) in child" :key="index">
          <router-link
            v-if="is_visible"
            :to="route_link"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li :class="[isActive && isExactActive && 'active']">
              <a :href="href" @click="navigate">{{ title }}</a>
            </li>
          </router-link>
        </div>
      </ul>
    </li>
  </router-link> -->
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
    route_link: {
      type: String,
      required: false,
    },
    route_name: {
      type: String,
      required: false,
    },
    has_children: {
      type: Boolean,
      default: false,
    },
    is_visible: {
      type: Boolean,
      default: true,
    },
    child: {
      type: Array,
    },
    is_exact: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    isLinkActive() {
      if (this.route_link === this.$route.path && this.route_link !== "#")
        return true;
      if (!this.has_children) return false;

      for (let i = 0; i < this.child.length; i++) {
        let child = this.child[i];
        //console.log(this.$route.path.includes(child.route_link));
        if (
          (child.route_link === "/admin" && this.$route.path !== "/admin") ||
          child.route_link === "#"
        )
          continue;
        if (this.$route.path.includes(child.route_link)) {
          return true;
        }
      }
      return false;
    },
  },
  methods: {
    getId(route) {
      return route.substr(1);
    },
  },
};
</script>

<style lang="scss">
.c-nav {
  align-items: center;

  p {
    flex: 1;
  }

  svg {
    transition: 0.3s;
    rotate: 180deg;
  }

  &.collapsed {
    svg {
      rotate: 0deg;
    }
  }
}

.nav-item-c:hover {
  > a {
    background-color: #f44336 !important;
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14),
      0 7px 10px -5px rgba(244, 67, 54, 0.4);
    color: #fff !important;

    p,
    i,
    span,
    .material-icons {
      color: #fff !important;
    }
    svg {
      fill: #fff;
    }
  }
}

.nav-item.active {
  background-color: #f44336;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14),
    0 7px 10px -5px rgba(244, 67, 54, 0.4);
  color: #fff !important;

  p,
  i,
  span,
  .material-icons {
    color: #fff !important;
  }
}
</style>
